<template>
  <div class="ml-menu-left">
    <!--    <div class="block-data">-->
    <!--      <div class="item d-flex pointer" v-for="item in listData" :key="item.id" :class="{'active': item.id == active}" @click="selectClass(item)"><span class="class-name">{{item.name}}</span><img src="~/mlearn/icons/menu/left/fire.svg" class="fire"/></div>-->
    <!--    </div>-->
    <div class="block-data" v-if="hasSub">
      <div class="item form-data align-items-center" @click="goToPath('/bang-xep-hang')">
        <span class="text-center"><img src="~/mlearn/icons/menu/left/rank.svg" class="rank pointer"/></span>
        <div class="content pointer">Bảng xếp hạng</div>
      </div>
      <div class="item form-data align-items-center" @click="goToPath('/noi-quy-hoi-bai')">
        <span class="text-center"><img src="~/mlearn/icons/menu/left/rules.svg" class="rules pointer"/></span>
        <div class="content pointer">Nội quy hỏi bài</div>
      </div>
      <div class="item form-data align-items-center" @click="goToPath('/co-che-cong-diem')">
        <span class="text-center"><img src="~/mlearn/icons/menu/left/point.svg" class="point pointer"/></span>
        <div class="content pointer">Cơ chế cộng điểm</div>
      </div>
    </div>
  </div>
</template>

<script>
// import Resource from '~/common/api/resource'

// const classResource = new Resource('category/class')
export default {
  props: {
    hasSub: {
      type: Boolean,
      default: false,
      required: false
    },
    items: {
      type: Array,
      default () {
        return []
      },
      required: false
    }
  },
  data () {
    return {
      listData: [],
      active: ''
    }
  },
  created () {
    this.listData = this.items
    if (!this.items.length) {
      // const { data } = await classResource.list()
      this.listData = this.$store.getters['context/classes']
    }
  },
  methods: {
    selectClass (item) {
      if (this.active === item.id) {
        this.active = ''
      } else {
        this.active = item.id
      }
      this.$emit('selectClass', this.active)
    },
    goToPath (path) {
      this.$router.push(path)
    }
  }
}
</script>

<style lang="scss" scoped>
.ml-menu-left {
  .block-data {
    margin-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, .15);
    @media (max-width: 1366px) {
    }
    @media (max-width: 992px) {
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      // padding-bottom: 100px;
      border-bottom: 0;
      @media (max-width: 1366px) {
      }
      @media (max-width: 992px) {
      }
    }

    .item {
      font-weight: 500;
      font-size: 14px;
      transition: all .3s linear;
      user-select: none;
      border-radius: 8px;
      padding: 14px 12px;
      @media (max-width: 1366px) {
        border-radius: 5px;
        padding: 10px;
      }
      @media (max-width: 992px) {
        padding: 10px 7px;
      }

      &.active, &:hover {
        background: var(--primary);
        color: var(--white);
      }

      &.form-data {
        span {
          width: 20px;
          margin-right: 10px;
          @media (max-width: 1366px) {
            width: 18px;
            margin-right: 8px;
          }
          @media (max-width: 992px) {
          }
        }

        .content {
          font-weight: 500;
          font-size: 14px;
          @media (max-width: 1366px) {
            font-size: 13px;
          }
          @media (max-width: 992px) {
          }
        }
      }

      span {
        &.class-name {
          min-width: 60px;
          @media (max-width: 1366px) {
          }
          @media (max-width: 992px) {
          }
        }
      }
    }
  }
}
</style>
