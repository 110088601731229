<template>
  <div class="list-playlist">
    <div class="row">
      <div class="col-lg-3 scrollbar-secondary list-scroll">
        <PlaylistItem class="col-lg-12" :action="false" :item="{ name: 'Đề đã làm', number: countItem.end }" to="da-lam" @activeTab="activeTab" :is-active="tab.to === 'da-lam'"/>
        <PlaylistItem class="col-lg-12" :action="false" :item="{ name: 'Đề đang tạm dừng', number: countItem.pause }" to="tam-dung" @activeTab="activeTab" :is-active="tab.to === 'tam-dung'"/>
        <PlaylistItem v-for="(item, ind) in listData" :key="ind" :item="item" class="col-lg-12" :to="item.slug" @deleted="loadData(1)" :is-active="tab.to === item.slug" @activeTab="activeTab"/>
      </div>
      <div class="col-lg-9">
        <PlaylistQuiz :tab="tab"/>
      </div>
    </div>
    <!--    <Pagination class="mt-3" :total="query.total" :value="query.page" :limit="query.limit" @change="loadData" />-->
  </div>
</template>

<script>
// import Pagination from '~/components/mlearn/pagination/index'
import PlaylistItem from '~/components/mlearn/playlist/item.vue'
import PlaylistQuiz from '~/components/mlearn/playlist/quiz.vue'
import Resource from '~/common/api/resource'

const playlistResource = new Resource('library/playlist/all')
const countItemResource = new Resource('library/exam/myExam/count')
export default {
  components: {
    PlaylistItem,
    PlaylistQuiz
    // Pagination
  },
  data () {
    return {
      query: {
        limit: 12,
        page: 1,
        total: 0,
        type: 'exam'
      },
      listData: [],
      countItem: {
        pause: 0,
        end: 0
      },
      tab: {
        name: 'Đề đã làm',
        to: 'da-lam'
      }
    }
  },
  created () {
    this.loadData()
    this.loadCount()
  },
  methods: {
    async loadCount () {
      const {data} = await countItemResource.list()
      this.countItem = data
    },
    async loadData (page = 1) {
      this.query.page = page
      const {data} = await playlistResource.list(this.query)
      this.listData = data
      this.getActiveTab()
    },
    activeTab (tab) {
      if (this.tab?.to !== tab.to) {
        this.tab = tab
        this.$router.push({
          query: {
            ...this.$route.query,
            type: tab.to
          }
        })
      }
    },
    getActiveTab () {
      const playlist = this.$route.query.tab
      const type = this.$route.query.type

      if (playlist === '3') {
        if (!type || type === 'da-lam') {
          this.tab = {
            name: 'Đề đã làm',
            to: 'da-lam'
          }
        } else if (type === 'tam-dung') {
          this.tab = {
            name: 'Đề đang tạm dừng',
            to: 'tam-dung'
          }
        } else {
          const activePlaylist = this.listData?.find(i => i.slug === type)
          if (activePlaylist) {
            this.tab = {
              name: activePlaylist.name,
              to: activePlaylist.slug
            }
          } else {
            this.tab = {
              name: 'Đề đã làm',
              to: 'da-lam'
            }
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.list-playlist {
  .list-scroll {
    > [class*="col-"] {
      padding-top: 18px;
      @media (max-width: 576px) {
        padding-top: 14px;
      }
    }
  }
}

.list-scroll {
  overflow-y: auto;
  max-height: 90vh;
  margin-bottom: 20px;
  background: transparent;
  @media (max-width: 576px) {
    max-height: 50vh;
  }
}
</style>
