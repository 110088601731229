<template>
  <b-modal v-model="showModal" id="modal-register" body-class="position-relative" modal-class="scrollbar px-0" hide-header hide-footer no-close-on-backdrop no-close-on-esc centered>
    <img alt="logo" src="~/mlearn/icons/menu/logo.svg" class="logo">
    <img alt="logo" src="~/mlearn/icons/modal/close-modal.svg" class="close pointer position-absolute" @click="showModal = false">
    <div class="title">Đăng ký</div>
    <div class="description">Học tập và giao lưu với hàng triệu học viên trên mọi miền đất nước</div>
    <div class="group-item account">
      <div class="label">Họ tên <span>*</span></div>
      <div>
        <input type="text" v-model="form.fullName" placeholder="Nhập họ tên"/>
      </div>
    </div>
    <div class="group-item email">
      <div class="label">Email <span>*</span></div>
      <div>
        <input type="text" v-model="form.email" placeholder="Email"/>
      </div>
    </div>
    <div class="group-item mobile">
      <div class="label">Số điện thoại <span>*</span></div>
      <div>
        <input type="text" v-model="form.mobile" placeholder="Nhập số điện thoại để nhận hỗ trợ" autocomplete="mobile number"/>
      </div>
    </div>
    <div class="group-item password">
      <div class="label">Mật khẩu <span>*</span></div>
      <div class="position-relative">
        <input :type="showpassword ? 'text' : 'password'" v-model="form.password" placeholder="Nhập mật khẩu" autocomplete="new password"/>
        <img :src="showpassword ? IconHide : IconShow" alt="View" class="action pointer position-center" @click="showpassword = !showpassword">
      </div>
    </div>
    <div class="group-item password" hidden>
      <div class="label">Nhập lại mật khẩu <span>*</span></div>
      <div class="position-relative">
        <input :type="showpassword ? 'text' : 'password'" v-model="form.repeat_password" placeholder="Xác nhận mật khẩu" autocomplete="confirm password"/>
        <img :src="showpassword ? IconHide : IconShow" alt="View" class="action pointer position-center" @click="showpassword = !showpassword">
      </div>
    </div>
    <div class="group-item">
      <b-form-checkbox v-model="term" :unchecked-value="0">
        Tôi đồng ý với chính sách và điều khoản sử dụng của Mlearn
      </b-form-checkbox>
    </div>
    <!-- <div class="group-item">
      <recaptcha />
    </div> -->
    <div class="group-item">
      <button @click="register()" class="register" :class="{ disable: loading }">Đăng ký</button>
    </div>
    <div class="line"></div>
    <div class="or text-center">Hoặc</div>
    <div class="group-item">
      <button type="button" class="facebook" @click="fbAuthUser()">
        <img src="~/mlearn/icons/modal/facebook.svg" alt="" class="mr-2"/> Đăng ký bằng Facebook
      </button>
    </div>
    <div class="group-item">
      <button type="button" class="google" @click="loginGoogle()">
        <img src="~/mlearn/icons/modal/google.svg" alt="" class="mr-2"/> Đăng ký bằng Google
      </button>
    </div>
  </b-modal>
</template>

<script>
import Cookies from 'js-cookie'
import {register, loginGoogle, loginFacebook} from '~/common/api/auth'
import IconShow from '~/assets/mlearn/icons/change-pass/show.svg'
import IconHide from '~/assets/mlearn/icons/change-pass/hide.svg'

export default {
  data () {
    return {
      IconShow,
      IconHide,
      loading: false,
      showModal: false,
      form: {
        email: '',
        password: '',
        repeat_password: '',
        fullName: '',
        mobile: ''
      },
      term: 1,
      showpassword: false
    }
  },
  computed: {
    isMobile () {
      return this.$store.state.isMobile
    }
  },
  // beforeDestroy () {
  //   this.$recaptcha.destroy()
  // },
  methods: {
    loginGoogle () {
      if (this.isMobile) {
        const path = this.$route.fullPath
        Cookies.set('redirect', path)
        const url = 'https://accounts.google.com/o/oauth2/v2/auth?scope=openid%20profile%20email&response_type=token%20id_token&redirect_uri=' + process.env.NUXT_ENV_GOOGLE_REDIECT + '&client_id=' + process.env.NUXT_ENV_GOOGLE_CLIENT_ID + '&nonce=thtfmJzzrwhiG-LYFecUT&&flowName=GeneralOAuthFlow'
        window.location.href = url
        return
      }
      this.$gAuth.signIn()
        .then((googleUser) => {
          const accessToken = googleUser.getAuthResponse().id_token
          this.$emit('Login')
          const params = {
            accessToken
          }
          loginGoogle(params)
            .then((response) => {
              this.$emit('Login')
              this.$store.commit('SET_AUTHENTICATED', true)
              this.$store.commit('SET_TOKEN', response.data.accessToken)
              this.$store.commit('SET_USER', {
                email: response.data.email,
                fullName: response.data.fullName,
                userName: response.data.userName,
                avatar: response.data.avatar,
                uuid: response.data.uuid
              })
              this.connectSocket()
              this.$router.go(this.$router.currentRoute)
            })
            .catch(() => {
            })
            .finally(() => {
              this.loading = false
            })
        })
    },
    fbAuthUser () {
      window.FB.getLoginStatus((response) => {
        if (response.status !== 'connected') {
          return window.FB.login((response) => {
            this.handleFbResponse(response)
          }, {
            scope: 'public_profile,email',
            enable_profile_selector: true,
            auth_type: 'rerequest',
            return_scopes: true
          })
        } else {
          this.handleFbResponse(response)
        }
      })
    },
    handleFbResponse (response) {
      if (response.status === 'connected') {
        const accessToken = response.authResponse.accessToken
        // const facebook_id = response.authResponse.userID
        const params = {
          accessToken
        }
        loginFacebook(params)
          .then((response) => {
            this.$emit('Login')
            this.$store.commit('SET_AUTHENTICATED', true)
            this.$store.commit('SET_TOKEN', response.data.accessToken)
            this.$store.commit('SET_USER', {
              email: response.data.email,
              fullName: response.data.fullName,
              userName: response.data.userName,
              avatar: response.data.avatar,
              uuid: response.data.uuid
            })
            this.connectSocket()
            this.$router.go(this.$router.currentRoute)
          })
          .catch(() => {
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        return false
      }
    },
    register () {
      if (this.loading) {
        return
      }
      let checkError = false
      let message = ''
      this.form.fullName = this.form.fullName.trim()
      this.form.mobile = this.form.mobile.trim()
      this.form.email = this.form.email.trim()
      this.form.password = this.form.password.trim()
      this.form.repeat_password = this.form.password
      if (this.form.mobile && this.form.mobile.startsWith('84')) {
        this.mobile = '0' + this.form.mobile.substring(2)
      }
      if (!this.form.fullName) {
        message = 'Họ tên không được để trống'
        checkError = true
      }
      if (this.form.fullName.length > 50) {
        message = 'Họ tên không được dài quá 50 ký tự'
        checkError = true
      }
      if (!this.$helper.validEmail(this.form.email)) {
        message = 'Email không đúng định dạng'
        checkError = true
      }
      if (!this.form.email) {
        message = 'Email ko được để trống'
        checkError = true
      }
      if (!this.form.password) {
        message = 'Mật khẩu không được để trống'
        checkError = true
      }
      if (this.form.password.length < 8 || this.form.password.length > 20) {
        message = 'Mật khẩu dài từ 8 - 20 ký tự'
        checkError = true
      }
      if (!this.form.mobile) {
        message = 'Số điện thoại không được để trống'
        checkError = true
      }
      if (!this.$helper.validPhone(this.form.mobile)) {
        message = 'Số điện thoại không đúng định dạng'
        checkError = true
      }

      if (this.form.password !== this.form.repeat_password) {
        message = 'Mật khẩu không trùng khớp'
        checkError = true
      }
      if (this.form.password && this.$helper.emoji(this.form.password)) {
        message = 'Mật khẩu gồm chữ cái, số hoặc kí tự đặc biệt'
        checkError = true
      }
      if (this.term === 0) {
        message = 'Bạn cần đồng ý với chính sách điều khoản của chúng tôi'
        checkError = true
      }
      if (checkError) {
        this.$notify({
          message,
          type: 'warning'
        })
        return
      }
      this.loading = true
      // add google recaptcha token
      // let recaptchaToken = ''
      // try {
      //   recaptchaToken = await this.$recaptcha.getResponse()
      // } catch (error) {
      //   console.log('Get captcha error:', error)
      // }
      // if (!recaptchaToken) {
      //   this.$notify({
      //     message: 'Chưa xác thực captcha',
      //     type: 'warning'
      //   })
      //   return
      // }
      this.form.recaptcha_token = ''
      this.loading = true
      register(this.form)
        .then(() => {
          this.$notify({
            message: 'Đăng ký tài khoản thành công.',
            type: 'success'
          })
          this.$bvModal.hide('modal-register')
          this.reset()
          this.$bvModal.show('modal-login')
        })
        .catch((err) => {
          this.$notify({
            message: err.response.data.message,
            type: 'error'
          })
        })
        .finally(() => {
          this.loading = false
          // this.$recaptcha.reset()
        })
    },
    reset () {
      this.form = {
        email: '',
        password: '',
        repeat_password: '',
        fullName: '',
        mobile: ''
      }
    }
  }
}
</script>
<style lang="scss">
#modal-register {
  &___BV_modal_outer_ {
    z-index: 9999 !important;

    .modal-dialog {
      max-width: 456px;

      .modal-content {
        .modal-body {
          padding: 40px 44px 30px;
          @media (max-width: 576px) {
            padding: 40px 24px 30px;
          }

          .close {
            top: 24px;
            right: 24px;
            @media (max-width: 576px) {
              top: 16px;
              right: 24px;
            }
          }

          .title {
            font-weight: 600;
            font-size: 20px;
            padding-top: 24px;
          }

          .description {
            font-weight: 500;
            font-size: 11px;
            color: #65676B;
          }

          .group-item {
            padding-top: 20px;
            @media (max-width: 576px) {
              padding-top: 16px;
            }

            .label {
              margin-bottom: 5px;
              font-size: 11px;
              padding-left: 16px;

              span {
                color: var(--danger);
              }
            }

            input {
              background: #F2F2F2;
              border: 0.5px solid #E5E5E5 !important;
              border-radius: 6px;
              padding: 14px 16px;
              font-size: 15px;
              width: 100%;
              @media (max-width: 576px) {
                padding: 10px 16px;
                font-size: 12px;
              }

              &::placeholder {
                color: #808080;
              }
            }

            .custom-control-label {
              font-size: 12px;
              cursor: pointer;
            }

            button {
              border-radius: 6px;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              font-weight: 500;
              font-size: 12px;
              padding: 10px 0;

              &.register {
                font-weight: bold;
                font-size: 15px;
                background: var(--primary);
                color: var(--white);
              }

              &.facebook {
                background: #3C66BE;
                color: var(--white);
              }

              &.google {
                background: var(--white);
                border: 1px solid #EBEBEB !important;
              }
            }

            &.no-account {
              font-size: 12px;

              a {
                font-weight: 500;
              }
            }

            &.password {
              .action {
                right: 16px;
              }
            }
          }

          .position-center {
            left: 95%;
          }

          .line {
            height: 1px;
            background: #EBEBEB;
            margin: 20px 0;
          }

          .or {
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
