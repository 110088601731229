<template>
  <div class="text-center font-weight-bold loading-data">
    <img src="~/mlearn/icons/no-data.svg" class="icon" alt="no data"/>
    {{ text }}
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default () {
        return 'Đang tải dữ liệu'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.loading-data {
}
</style>
