<template>
  <img src="~/mlearn/icons/document/lock-list.svg" alt="lock" class="lock-file"/>
</template>
<script>
export default {
}
</script>
<style lang="scss" scoped>
.lock-file{
  position: absolute;
  top: 0;
  right: 0;
}
</style>
