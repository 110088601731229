<template>
  <div class="list-playlist">
    <div class="row">
      <div class="col-lg-3 scrollbar-secondary list-scroll ">
        <PlaylistItem class="col-lg-12" :action="false" :item="{ name: 'Khóa học của tôi', number: myVideo }" to="dang-hoc" @activeTab="activeTab" :is-active="tab.to === 'dang-hoc'"/>
        <PlaylistItem class="col-lg-12" :action="false" :item="{ name: 'Theo dõi', number: myFollow }" to="theo-doi" @activeTab="activeTab" :is-active="tab.to === 'theo-doi'"/>
        <PlaylistItem v-for="(item, ind) in listData" :key="ind" :item="item" class="col-lg-12" :to="item.slug" @deleted="loadData(1)" :is-active="tab.to === item.slug" @activeTab="activeTab"/>
      </div>
      <div class="col-lg-9">
        <PlaylistVideo :tab="tab"/>
      </div>
      <!--      <PlaylistItem class="col-lg-3" :action="false" :item="{ name: 'Khóa học của tôi', number: myVideo }" to="/thu-vien-cua-toi/khoa-hoc/dang-hoc"/>-->
      <!--      <PlaylistItem class="col-lg-3" :action="false" :item="{ name: 'Theo dõi', number: myFollow }" to="/thu-vien-cua-toi/khoa-hoc/theo-doi"/>-->
      <!--      <PlaylistItem v-for="(item, ind) in listData" :key="ind" :item="item" class="col-lg-3" :to="'/thu-vien-cua-toi/khoa-hoc/'+item.slug" @deleted="loadData(1)"/>-->
    </div>
    <!-- <NoData v-if="!listData.length" /> -->
    <!--    <Pagination class="mt-3" :total="query.total" :value="query.page" :limit="query.limit" @change="loadData"/>-->
  </div>
</template>

<script>
// import Pagination from '~/components/mlearn/pagination/index'
import PlaylistItem from '~/components/mlearn/playlist/item.vue'
import PlaylistVideo from '~/components/mlearn/playlist/video.vue'
import Resource from '~/common/api/resource'

const playlistResource = new Resource('library/playlist/all')
const myVideoResource = new Resource('library/topic/list-learning')
const myFollowoResource = new Resource('library/topic/listFollow')
export default {
  components: {
    PlaylistItem,
    PlaylistVideo
    // Pagination
  },
  data () {
    return {
      query: {
        limit: 100,
        page: 1,
        total: 0
      },
      myVideo: 0,
      myFollow: 0,
      listData: [],
      tab: {
        name: 'Khóa học của tôi',
        to: 'dang-hoc'
      }
    }
  },
  created () {
    this.loadData()
    this.getTotalMyVideo()
    this.getTotalMyFollow()
  },
  methods: {
    async loadData (page = 1) {
      this.query.page = page
      const {data} = await playlistResource.list(this.query)
      this.listData = data
      this.getActiveTab()
    },
    async getTotalMyVideo () {
      const {total} = await myVideoResource.list()
      this.myVideo = total
    },
    async getTotalMyFollow () {
      const {total} = await myFollowoResource.list()
      this.myFollow = total
    },
    activeTab (tab) {
      if (this.tab?.to !== tab.to) {
        this.tab = tab
        this.$router.push({
          query: {
            ...this.$route.query,
            type: tab.to
          }
        })
      }
    },
    getActiveTab () {
      const playlist = this.$route.query.tab
      const type = this.$route.query.type

      if (!playlist || playlist === '1') {
        if (!type || type === 'dang-hoc') {
          this.tab = {
            name: 'Khóa học của tôi',
            to: 'dang-hoc'
          }
        } else if (type === 'theo-doi') {
          this.tab = {
            name: 'Theo dõi',
            to: 'theo-doi'
          }
        } else {
          const activePlaylist = this.listData?.find(i => i.slug === type)
          if (activePlaylist) {
            this.tab = {
              name: activePlaylist.name,
              to: activePlaylist.slug
            }
          } else {
            this.tab = {
              name: 'Khóa học của tôi',
              to: 'dang-hoc'
            }
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.list-playlist {
  .list-scroll {
    > [class*="col-"] {
      padding-top: 18px;
      @media (max-width: 576px) {
        padding-top: 14px;
      }
    }
  }
}

.list-scroll {
  overflow-y: auto;
  max-height: 90vh;
  margin-bottom: 20px;
  background: transparent;
  @media (max-width: 576px) {
    max-height: 50vh;
  }
}
</style>
