<template>
  <div class="list-playlist ">
    <div class="row">
      <div class="col-lg-3 scrollbar-secondary list-scroll ">
        <PlaylistItem class="col-lg-12" :action="false" :item="{ name: 'Tài liệu đã xem', number: viewed }" to="da-xem" @activeTab="activeTab" :is-active="tab.to === 'da-xem'"/>
        <PlaylistItem v-for="(item, ind) in listData" :key="ind" :item="item" class="col-lg-12" :to="item.slug" @deleted="loadData(1)" :is-active="tab.to === item.slug" @activeTab="activeTab"/>
      </div>
      <div class="col-lg-9">
        <PlaylistDocument :tab="tab"/>
      </div>
    </div>
    <!--    <Pagination class="mt-3" :total="query.total" :value="query.page" :limit="query.limit" @change="loadData" />-->
  </div>
</template>

<script>
// import Pagination from '~/components/mlearn/pagination/index'
import PlaylistItem from '~/components/mlearn/playlist/item.vue'
import PlaylistDocument from '~/components/mlearn/playlist/document.vue'
import Resource from '~/common/api/resource'

const playlistResource = new Resource('library/playlist/all')
const countItemResource = new Resource('library/file/viewed/count')
export default {
  components: {
    PlaylistItem,
    PlaylistDocument
    // Pagination
  },
  data () {
    return {
      query: {
        limit: 12,
        page: 1,
        total: 0,
        type: 'doc'
      },
      listData: [],
      viewed: 0,
      tab: {
        name: 'Tài liệu đã xem',
        to: 'da-xem'
      }
    }
  },
  created () {
    this.loadData()
    this.loadCount()
  },
  methods: {
    async loadCount () {
      const {data} = await countItemResource.list()
      this.viewed = data
    },
    async loadData (page = 1) {
      this.query.page = page
      const {data} = await playlistResource.list(this.query)
      this.listData = data
      this.getActiveTab()
    },
    activeTab (tab) {
      if (this.tab?.to !== tab.to) {
        this.tab = tab
        this.$router.push({
          query: {
            ...this.$route.query,
            type: tab.to
          }
        })
      }
    },
    getActiveTab () {
      const playlist = this.$route.query.tab
      const type = this.$route.query.type
      if (playlist === '2') {
        if (!type || type === 'da-xem') {
          this.tab = {
            name: 'Tài liệu đã xem',
            to: 'da-xem'
          }
        } else {
          const activePlaylist = this.listData?.find(i => i.slug === type)
          if (activePlaylist) {
            this.tab = {
              name: activePlaylist.name,
              to: activePlaylist.slug
            }
          } else {
            this.tab = {
              name: 'Tài liệu đã xem',
              to: 'da-xem'
            }
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.list-playlist {
  .list-scroll {
    > [class*="col-"] {
      padding-top: 18px;
      @media (max-width: 576px) {
        padding-top: 14px;
      }
    }
  }
}

.list-scroll {
  overflow-y: auto;
  max-height: 90vh;
  margin-bottom: 20px;
  background: transparent;
  @media (max-width: 576px) {
    max-height: 50vh;
  }
}
</style>
