<template>
  <b-modal id="modal-verify-mobile" v-model="modalShow" centered hide-footer @hidden="hiddenVerify" @shown="hideFormEnterOtp" no-close-on-backdrop no-close-on-esc>
    <div class="content-verify" v-if="!showEnterOtp">
      <div v-if="!userMobile">
        <h4>Nhập số điện thoại để xác thực</h4>
        <p class="mb-text">Số điện thoại</p>
        <b-input-group prepend="+84" class="mb-2 mr-sm-2 mb-sm-0 mb-input">
          <b-input v-model="mobile" required ref="input-phone" placeholder="Nhập số điện thoại" class="shadow-none"></b-input><br>
        </b-input-group>
        <span class="text-danger">{{ textError }}</span>
        <p class="mb-desc">Bạn đã đăng nhập thành công, vui lòng nhập số điện thoại để xác thực và bảo mật tài khoản.</p>
        <p class="mb-info">(Mã xác thực sẽ được gửi miễn phí qua cuộc gọi)</p>
      </div>
      <div v-else>
        <h4>Xác thực số điện thoại</h4>
        <p class="mb-text">Số điện thoại</p>
        <div class="fake-input d-flex">
          <span class="code border-0 text-center">+84</span>
          <span class="input">{{ userMobile }}</span>
        </div>
        <p class="mb-desc">Vui lòng xác thực số điện thoại và bảo mật tài khoản.</p>
        <p class="mb-info">(Mã xác thực sẽ được gửi miễn phí qua cuộc gọi)</p>
      </div>
      <div class="d-flex flex-row-reverse bd-highlight">
        <b-button class="btn-send" @click="handleSendVerify">Gửi mã xác thực</b-button>
      </div>
    </div>
    <div class="content-otp" v-if="showEnterOtp">
      <h4>Xác thực tài khoản</h4>
      <p class="verify-text">Nhập mã xác thực vừa gửi đến số điện thoại của bạn</p>
      <p class="verify-label">Mã xác thực</p>
      <b-form-input class="verify-code" v-model="otpCode" required ref="input-otp" placeholder="Nhập mã xác thực"></b-form-input>
      <span class="text-danger">{{ textOtpError }}</span>
      <div class="verify-bottom d-flex">
        <p class="verify-desc">Không nhận được mã? <span class="verify-countdown">({{ time }}s)</span></p>
        <p class="re-send pointer mb-0 ml-auto" v-if="time===0" @click="resendVerify">Gửi lại mã</p>
      </div>
      <div class="d-flex flex-row-reverse bd-highlight">
        <b-button class="btn-send" @click="verifyOtp">Hoàn thành</b-button>
      </div>
    </div>
  </b-modal>
</template>
<script type="text/javascript">
import Cookies from 'js-cookie'
import { mapState } from 'vuex'
import { sendVerifyMobile, verifyOtp, resendVerify } from '~/common/api/verify'
import { removeVerify } from '~/common/utils/auth'

export default {
  data () {
    return {
      mobile: '',
      otpCode: '',
      showEnterOtp: false,
      textError: '',
      textOtpError: '',
      interval: null,
      time: 90
    }
  },
  computed: {
    ...mapState([
      'authenticated',
      'user',
      'isVerifiedMobile',
      'user'
    ]),
    modalShow () {
      if (this.$route.name === 'payment-code') {
        return false
      }
      const authenticated = this.authenticated
      const isVerifiedMobile = this.isVerifiedMobile
      const hiddenVerify = Cookies.get('hiddenVerify')
      return authenticated && !hiddenVerify && !isVerifiedMobile
    },
    userMobile () {
      if (this.user && this.user.mobile) {
        return this.user.mobile.substring(1)
      }
      return ''
    }
  },
  watch: {
    time (val) {
      if (val === 0) {
        clearInterval(this.interval)
      }
    }
  },
  methods: {
    showFormEnterOtp () {
      this.time = 90
      this.interval = setInterval(() => {
        this.time--
      }, 1000)
      this.showEnterOtp = true
      return this.showEnterOtp
    },
    hideFormEnterOtp () {
      this.showEnterOtp = false
      this.otpCode = ''
      if (this.interval) {
        clearInterval(this.interval)
      }
      this.time = 90
    },
    handleSendVerify () {
      let newMobile = this.mobile
      if (newMobile && newMobile.startsWith('0')) {
        newMobile = newMobile.substring(1)
      }
      const mobile = this.userMobile ? '0' + this.userMobile : '0' + newMobile
      if (!this.$helper.validPhone(mobile)) {
        this.textError = 'Số điện thoại không hợp lệ'
        return false
      }
      sendVerifyMobile({ mobile })
        .then((response) => {
          if (response.status === 200) {
            this.showFormEnterOtp()
          }
        })
        .catch((err) => {
          return this.$notify({
            title: 'Lỗi',
            message: err.response.data.message.replace(mobile, '+84' + mobile.substring(1)),
            type: 'error'
          })
        })
    },
    resendVerify () {
      resendVerify()
        .then((response) => {
          if (response.status === 200) {
            this.otpCode = ''
            this.showFormEnterOtp()
          }
        })
        .catch((err) => {
          return this.$notify({
            title: 'Lỗi',
            message: err.response.data.message,
            type: 'error'
          })
        })
    },
    verifyOtp () {
      if (!this.otpCode) {
        this.textOtpError = 'Vui lòng nhập mã xác thực.'
        this.$refs['input-otp'].$el.classList.add('is-invalid')
        return false
      }
      const data = {
        otp: this.otpCode
      }
      verifyOtp(data)
        .then((response) => {
          if (response.status === 200) {
            removeVerify()
            this.$store.dispatch('setVerifiedMobile', 1)
            // this.$store.dispatch('setUserMobile', this.mobile);
            this.$bvModal.hide('modal-verify-mobile')
            location.reload()
            return this.$notify({
              title: 'Thành công',
              message: 'Xác thực số điện thoại thành công.',
              type: 'success'
            })
          }
        })
        .catch((err) => {
          return this.$notify({
            title: 'Lỗi',
            message: err.response.data.message,
            type: 'error'
          })
        })
    },
    hiddenVerify () {
      Cookies.set('hiddenVerify', true, {
        expires: 1
      })
    }
  }
}
</script>
<style lang="scss">
#modal-verify-mobile{
  &___BV_modal_outer_{
    z-index: 9999 !important;
    .modal-dialog{
      .modal-content{
        .modal-header{
          border-bottom: initial;
          padding-bottom: 0;
          button{
            &.close{
              padding-top: .5rem;
              padding-bottom: .5rem;
            }
          }
        }
        .modal-body{
          input {
            height: 40px;
            @media (max-width:576px) {
              height: 35px;
            }
          }
          h4 {
            font-weight: bold;
            margin-bottom: 12px;
            font-size: 22px;
            @media (max-width:576px) {
              font-size: 18px;
            }
          }
          .mb-text {
            margin-bottom: 10px;
            font-size: 16px;
            @media (max-width:576px) {
              font-size: 14px;
            }
          }
          .mb-input {
            input {
              border-top-right-radius: 8px;
              border-bottom-right-radius: 8px;
              border: 1px solid #e1e7f5 !important;
              border-left: none;
            }
            .input-group-text {
              background: var(--primary);
              border: none;
              color: #ffffff;
              border-top-left-radius: 8px;
              border-bottom-left-radius: 8px;
              padding: 0.375rem 0.95rem;
              @media (max-width:576px) {}
            }
          }
          .mb-desc {
            margin-bottom: 0;
            font-size: 14px;
            margin-top: 12px;
            @media (max-width:576px) {}
          }
          header button {
            font-size: 16px;
            @media (max-width:576px) {}
          }
          .group-form {
            margin-bottom: 10px;
            @media (max-width:576px) {}
          }
          .btn-send {
            clear: both;
            background: var(--primary);
            border-color: var(--primary);
            border-radius: 26px;
            float: right;
            margin-top: 10px;
            padding: 10px 18px;
            margin-bottom: 10px;
            @media (max-width:576px) {
              margin-top: 5px;
              padding: 5px 10px;
              margin-bottom: 5px;
            }
          }
          .verify-text {
            color: #92a2bf;
            margin-bottom: 10px;
            font-size: 14px;
            @media (max-width:576px) {}
          }
          .verify-label {
            margin-bottom: 2px;
            font-size: 16px;
            @media (max-width:576px) {}
          }
          .verify-code {
            border-radius: 8px;
            border: solid 1px #dce9f3 !important;
            margin-bottom: 5px;
            @media (max-width:576px) {}
          }
          .verify-bottom {
            .verify-desc {
              margin-bottom: 0;
              margin-top: 15px;
              font-size: 16px;
              @media (max-width:576px) {
                font-size: 14px;
              }
              .verify-countdown {
                color: var(--primary);
              }
            }
            .re-send {
              color: var(--primary);
              text-decoration: underline;
              font-size: 16px;
              @media (max-width:576px) {
                font-size: 16px;
              }
            }
          }
          .fake-input {
            .code {
              background: var(--primary);
              color: #fff;
              border-top-left-radius: 8px;
              border-bottom-left-radius: 8px;
              font-size: 1rem;
              height: 40px;
              line-height: 40px;
              width: 50px;
              @media (max-width:576px) {
                height: 35px;
                line-height: 35px;
              }
            }
            .input {
              border-top-right-radius: 8px;
              border-bottom-right-radius: 8px;
              border: 1px solid #e1e7f5!important;
              border-left: none;
              width: 100%;
              padding: 0 .75rem;
              height: 40px;
              line-height: 40px;
              @media (max-width:576px) {
                height: 35px;
                line-height: 35px;
              }
            }
          }
          .mb-info {
            margin-top: 5px;
            font-size: 12px;
            @media (max-width:576px) {}
          }
        }
      }
    }
  }
}
</style>
