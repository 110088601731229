<template>
  <b-modal id="modal-verify-class" v-model="showModal" hide-header hide-footer centered no-close-on-backdrop no-close-on-esc dialog-class="m-auto">
    <img src="/icon/layout/close-modal-question.svg" alt="Close" class="pointer close-modal position-absolute" @click="showModal = false"/>
    <div class="title">Chọn lớp học</div>
    <div class="description">Chọn lớp học để MLearn hỗ trợ bạn học tập tốt hơn!</div>
    <div class="list-class row-cols-4 d-flex flex-wrap justify-content-center">
      <div v-for="item in listClass" :key="item.id">
        <div class="class-item text-center pointer" :class="{'active': item.id === classId}" @click="setClass(item.id)">{{ item.name }}</div>
      </div>
    </div>
    <div class="action">
      <button @click="updateClassInfo" :class="{'none': !classId}">Xác nhận</button>
    </div>
  </b-modal>
</template>

<script>
import {mapState} from 'vuex'
import Cookies from 'js-cookie'
import Resource from '~/common/api/resource'

const profileResource = new Resource('profile/user')
const classResource = new Resource('category/class')
export default {
  data () {
    return {
      showModal: false,
      listClass: [],
      profile: null,
      classId: ''
    }
  },
  computed: {
    ...mapState([
      'user'
    ]),
    isLibrary () {
      return /thu-vien/.test(this.$route.name)
    },
    isHome () {
      return this.$route.name === 'index'
    }
  },
  watch: {
    $route () {
      this.checkShowModal()
    }
  },
  mounted () {
    this.checkShowModal()
  },
  methods: {
    async checkShowModal () {
      this.loadClass()
      if (this.user) {
        this.classId = this.user.class_id
        const hiddenVerify = Cookies.get('hiddenVerify')
        const classID = Cookies.get('interestedClass')
        await this.loadProfile()
        if (!this.profile.classLevel && hiddenVerify && (!classID || classID === 'undefined')) {
          this.showModal = true
        }
      } else {
        const classID = Cookies.get('interestedClass')
        this.classId = classID
        if (!classID || classID === 'undefined') {
          this.showModal = true
        }
      }
    },
    async loadClass () {
      const params = {}
      this.listClass = await classResource.list(params)
        .then(res => res.data)
    },
    async loadProfile () {
      const {data} = await profileResource.list()
      this.profile = data
    },
    setClass (classId) {
      this.classId = classId
    },
    updateClassInfo () {
      if (this.user) {
        if (this.classId === this.user.class_id) {
          return
        }
        const classFind = this.listClass.find(it => it.id === this.classId)
        if (!classFind) {
          this.$notify({
            type: 'warning',
            message: 'Lớp học không tồn tại'
          })
        }
        const classLevel = classFind.name
        const data = {
          classLevel,
          classId: this.classId
        }
        profileResource.store(data)
          .then(() => {
            this.$notify({
              type: 'success',
              message: 'Cập nhật lớp học thành công'
            })
            location.reload()
          })
      } else {
        Cookies.set('interestedClass', this.classId)
        location.reload()
      }
    }
    // async loadData () {
    //   const params = {}
    //   this.listClass = this.$store.getters['context/classes']
    //   this.listClass = await classResource.list(params)
    //     .then(res => res.data)
    // }
  }
}
</script>

<style lang="scss">
#modal-verify-class {
  .modal-dialog {
    width: 515px;
    max-width: 100%;

    .modal-body {
      padding: 44px;
      @media (max-width: 576px) {
        padding: 20px !important;
      }

      .close-modal {
        right: 24px;
        top: 24px;
      }

      .title {
        font-weight: 500;
        font-size: 25px;
        text-align: center;
        color: #333333;
      }

      .description {
        font-weight: 400;
        font-size: 15px;
        text-align: center;
        color: #696969;
      }

      .list-class {
        > * {
          margin-top: 20px;

          .class-item {
            background: var(--color);
            border: 1px solid var(--color);
            border-radius: 30px;
            padding: 8px 0;
            max-width: 100%;
            margin: 0 10px;
            --color: #F8F8F8F8;
            color: #404040;
            @media (max-width: 576px) {
              margin: 0 5px;
            }

            &.active {
              --color: var(--warning);
              color: #FFFFFF;
            }
          }
        }
      }

      .action {
        margin-top: 25px;
        text-align: center;

        button {
          background: var(--primary);
          border-radius: 26px;
          padding: 12px 0;
          font-weight: 500;
          font-size: 18px;
          text-align: center;
          color: #FFFFFF;
          width: 300px;
          max-width: 100%;
          border: none;

          &.none {
            background: #C4C4C4;
          }
        }
      }
    }
  }
}
</style>
