<template>
  <div class="feed-right" v-if="user && listNotify.length > 0">
    <div class="title"><img src="~/mlearn/icons/home/feed.svg" alt="feed" class="mr-2" />HOẠT ĐỘNG GẦN ĐÂY</div>
    <div class="list-notify scrollbar">
      <div class="notify-item text-break" v-for="item in listNotify" :key="item.id">
        <div class="content" :title="getHtmlNotify(item)" v-html="getHtmlNotify(item)"></div>
        <div class="sub-content" v-if="item.title" v-html="item.title"></div>
      </div>
      <client-only>
        <scroll-loader :loader-method="getListNotify" :loader-disable="!loadMore"></scroll-loader>
      </client-only>
    </div>
  </div>
</template>
<script type="text/javascript">
import { mapState } from 'vuex'
import Resource from '~/common/api/resource'
import { stripHtml } from '~/common/utils'
const notifyUserApi = new Resource('notify/get-notify-user')
export default {
  filters: {
    htmlFilter (html) {
      return stripHtml(html)
    }
  },
  data () {
    return {
      listNotify: [],
      moreNoti: false,
      loadMore: true,
      offset: 0,
      limit: 10,
      loading: false
    }
  },
  computed: {
    ...mapState([
      'user'
    ])
  },
  mounted () {
    this.getListNotify()
  },
  sockets: {
    receiveNotification (data) {
      this.listNotify.unshift(data)
      this.offset++
    }
  },
  methods: {
    getListNotify () {
      if (!this.user || this.loading) { return }
      const param = {
        offset: this.offset,
        limit: this.limit,
        level: 0
      }
      this.loading = true
      notifyUserApi.list(param)
        .then((response) => {
          const listNoti = response.data.listNoti
          if (response.data && response.status === 200) {
            this.listNotify.push(...listNoti)
            if (listNoti.length < this.limit) {
              this.loadMore = false
            } else {
              this.loadMore = true
            }
            this.offset = this.offset + this.limit
          }
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    getHtmlNotify (item) {
      let html = item.text
      if (item.level === 1) {
        if (html.includes(' đã báo cáo vi phạm câu hỏi của bạn')) {
          const ind = html.indexOf(' đã báo cáo vi phạm câu hỏi của bạn')
          html = '<span class="user-name">' + html.substring(0, ind) + '</span>' + html.substring(ind)
        } else if (html.includes(' đã trả lời câu hỏi')) {
          const ind = html.indexOf(' đã trả lời câu hỏi')
          html = '<span class="user-name">' + html.substring(0, ind) + '</span>' + html.substring(ind)
        } else if (html.includes(' đã báo cáo vi phạm câu trả lời của bạn')) {
          const ind = html.indexOf(' đã báo cáo vi phạm câu trả lời của bạn')
          html = '<span class="user-name">' + html.substring(0, ind) + '</span>' + html.substring(ind)
        } else {
          // console.log(item.level + '------' + html)
        }
      } else if (item.level === 2) {
        if (html.includes('Bạn bị trừ ')) {
          const ind = 'Bạn bị trừ '.length
          html = html.substring(0, ind) + '<span class="remove-point">-' + (html.substring(ind).replace('-', '').replace('điểm', '')) + '</span> điểm'
        } else if (html.includes('Bạn được cộng ')) {
          const ind = 'Bạn được cộng '.length
          html = html.substring(0, ind) + '<span class="add-point">+' + (html.substring(ind).replace('+', '').replace('điểm', '')) + '</span> điểm'
        } else if (html.includes(' đã báo cáo vi phạm câu trả lời của bạn')) {
          const ind = html.indexOf(' đã báo cáo vi phạm câu trả lời của bạn')
          html = '<span class="user-name">' + html.substring(0, ind) + '</span>' + html.substring(ind)
        } else {
          // console.log(item.level + '------' + html)
        }
      } else {
        // console.log(item.level + '------' + html)
      }
      return html
    }
  }
}

</script>
<style lang="scss" scoped>
  .feed-right{
    background: var(--white);
    box-shadow: 0px 2px 4px rgba(174, 174, 174, 0.34);
    border-radius: 8px;
    .title {
			font-weight: bold;
			color: var(--white);
      background: var(--primary);
      border-radius: 8px 8px 0px 0px;
      font-size: 16px;
      padding:16px;
      @media (max-width:576px) {
        font-size: 14px;
        padding:10px 12px;
      }
      @media (max-width:992px) {}
      @media (max-width:1366px) {
        font-size: 15px;
        padding:10px 12px;
      }
    }
    .list-notify{
      overflow-x: hidden;
      border-radius:0 0 8px 8px;
      margin-right: 5px;
      max-height: 250px;
      padding:0 5px 16px 16px;
      @media (max-width:576px) {
        max-height: 200px;
        padding:0 5px 16px 16px;
      }
      @media (max-width:992px) {}
      @media (max-width:1366px) {
        max-height: 220px;
        padding:0 5px 12px 12px;
      }
      .notify-item{
        padding-bottom: .8rem;
        @media (max-width:576px) {
          padding-bottom: .5rem;
        }
        @media (max-width:992px) {}
        @media (max-width:1366px) {
          padding-bottom: .6rem;
        }
        &:first-child{
          padding-top: .8rem;
          @media (max-width:576px) {
            padding-top: .5rem;
          }
          @media (max-width:992px) {}
          @media (max-width:1366px) {
            padding-top: .6rem;
          }
        }
        &:last-child{
          padding-bottom: 0;
        }
        .content{
          font-size: 16px;
          @media (max-width:576px) {
            font-size: 14px;
          }
          @media (max-width:992px) {}
          @media (max-width:1366px) {
            font-size: 15px;
          }
          .user-name{
            font-weight: bold;
          }
          .add-point{
            font-weight: bold;
            color: #27AE60;
          }
          .remove-point{
            font-weight: bold;
            color: #E24C4C;
          }
        }
        .sub-content{
          color: #b0b0b0;
          font-size: 14px;
          @media (max-width:576px) {}
          @media (max-width:992px) {}
          @media (max-width:1366px) {}
        }
      }
    }
  }
</style>
