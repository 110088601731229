<template>
  <div class="mbl-menu">
    <div class="mbl-menu-desktop">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-9 col-lg-2">
            <img alt="logo" src="~/mlearn/icons/menu/logo.svg" class="logo pointer" @click="goToPage('/')">
          </div>
          <div class="col-lg-6 d-none d-lg-block">
            <div class="list-menu">
              <nuxt-link to="/hoi-bai" class="menu-item" :class="{'nuxt-link-exact-active': homeActive}">Hỏi bài</nuxt-link>
              <nuxt-link to="/khoa-hoc" class="menu-item" :class="{'nuxt-link-exact-active': videoActive}">Khóa học</nuxt-link>
              <nuxt-link to="/tai-lieu" class="menu-item" :class="{'nuxt-link-exact-active': documentActive}">Tài liệu</nuxt-link>
              <nuxt-link to="/trac-nghiem" class="menu-item" :class="{'nuxt-link-exact-active': quizActive}">Bộ đề</nuxt-link>
              <nuxt-link to="/blog" class="menu-item" :class="{'nuxt-link-exact-active': blogActive}">Blog</nuxt-link>
              <nuxt-link to="/goi-cuoc" class="menu-item " :class="{'nuxt-link-exact-active': packActive}">Gói cước</nuxt-link>
              <nuxt-link to="/tu-hoc" class="menu-item " :class="{'nuxt-link-exact-active': tuhocActive}">Tự học</nuxt-link>
            </div>
          </div>
          <div class="col-4 text-right d-none" :class="{'d-lg-flex justify-content-end': user, 'd-lg-block': !user}">
            <ClassList class="dropdown-verify-class d-none d-inline-flex mr-3" :all="false" placeholder="Bạn học lớp" :selected-id="interestedClass" @findClass="findClass"/>
            <template v-if="!user">
              <img alt="profile" src="~/mlearn/icons/menu/profile.svg" class="profile">
              <span class="register pointer" v-b-modal.modal-register>
                Đăng ký</span>
              <span class="split">|</span>
              <span class="login pointer" v-b-modal.modal-login>Đăng nhập</span>
            </template>
            <template v-else>
              <Notify class="mr-3"/>
              <Profile/>
            </template>
          </div>
          <div class="col-3 text-right d-block d-lg-none">
            <img src="~/mlearn/icons/menu/search.svg" @click="goToPage('/tim-kiem')" class="mr-3" :class="{'d-none': $route.name === 'tim-kiem'}" alt="">
            <img src="~/mlearn/icons/menu/open-menu.svg" class="pointer" @click.stop="openMenu" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="mbl-menu-mobile min-vh-100 overflow-auto to-left" :class="{'open':showMenu}">
      <img src="~/mlearn/icons/menu/close-menu.svg" class="pointer position-absolute close-menu" @click.stop="closeMenu" alt="">
      <div class="list-menu container">
        <div class="menu-item" v-if="user">
          <div class="form-data align-items-center" @click.prevent="goToPage('/thong-tin-ca-nhan')">
            <el-image class="avatar rounded-circle" :src="user.avatar || NoAvatar" alt="Avatar"></el-image>
            <div class="content">
              <div class="user-name display-html display-1-line">{{ user.fullName }}</div>
              <div class="user-info">Xem thông tin cá nhân</div>
            </div>
          </div>
        </div>
        <div class="menu-item menu" :class="homeActive ? 'active': ''">
          <div @click.prevent="goToPage('/')">Hỏi bài</div>
        </div>
        <div class="menu-item menu" :class="videoActive ? 'active': ''">
          <div @click.prevent="goToPage('/khoa-hoc')">Khóa học</div>
        </div>
        <div class="menu-item menu" :class="documentActive ? 'active': ''">
          <div @click.prevent="goToPage('/tai-lieu')">Tài liệu</div>
        </div>
        <div class="menu-item menu" :class="quizActive ? 'active': ''">
          <div @click.prevent="goToPage('/trac-nghiem')">Bộ đề</div>
        </div>
        <div class="menu-item menu" :class="blogActive ? 'active': ''">
          <div @click.prevent="goToPage('/blog')">Blog</div>
        </div>
        <div class="menu-item menu" :class="packActive ? 'active': ''">
          <div @click.prevent="goToPage('/goi-cuoc')">Gói cước</div>
        </div>
        <div class="menu-item menu" :class="tuhocActive ? 'active': ''">
          <div @click.prevent="goToPage('/tu-hoc')">Tự học</div>
        </div>
        <div class="menu-item line"></div>
        <div class="menu-item" :class="routeName === 'bang-xep-hang' ? 'active': ''">
          <div @click.prevent="goToPage('/bang-xep-hang')" class="d-flex align-items-baseline">
            <img src="~/mlearn/icons/menu/profile/rank.svg" alt="Bảng xếp hạng" class="mr-2"/>
            Bảng xếp hạng
          </div>
        </div>
        <div class="menu-item" :class="routeName === 'noi-quy-hoi-bai' ? 'active': ''">
          <div @click.prevent="goToPage('/noi-quy-hoi-bai')" class="d-flex align-items-baseline">
            <img src="~/mlearn/icons/menu/profile/rules.svg" alt="Nội quy hỏi bài" class="mr-2"/>
            Nội quy hỏi bài
          </div>
        </div>
        <div class="menu-item" :class="routeName === 'huong-dan-cong-diem' ? 'active': ''">
          <div @click.prevent="goToPage('/co-che-cong-diem')" class="d-flex align-items-baseline">
            <img src="~/mlearn/icons/menu/profile/my-point.svg" alt="Cơ chế cộng điểm" class="mr-2"/>
            Cơ chế cộng điểm
          </div>
        </div>
        <div class="menu-item line"></div>
        <template v-if="user">
          <div class="menu-item" :class="routeName === 'hoi-bai-cua-toi' ? 'active': ''">
            <div @click.prevent="goToPage('/hoi-bai-cua-toi')" class="d-flex align-items-baseline">
              <img src="~/mlearn/icons/menu/profile/my-question.svg" alt="Hỏi bài của tôi" class="mr-2"/>
              Hỏi bài của tôi
            </div>
          </div>
          <div class="menu-item" :class="routeName === 'thu-vien-cua-toi' ? 'active': ''">
            <div @click.prevent="goToPage('/thu-vien-cua-toi')" class="d-flex align-items-baseline">
              <img src="~/mlearn/icons/menu/profile/my-library.svg" alt="Thư viện của tôi" class="mr-2"/>
              Thư viện của tôi
            </div>
          </div>
          <div class="menu-item" :class="routeName === 'lich-su-thanh-toan' ? 'active': ''">
            <div @click.prevent="goToPage('/lich-su-thanh-toan')" class="d-flex align-items-baseline">
              <img src="~/mlearn/icons/menu/profile/history-payment.svg" alt="Lịch sử thanh toán" class="mr-2"/>
              Lịch sử thanh toán
            </div>
          </div>
          <div class="menu-item line"></div>
          <div class="menu-item" :class="routeName === 'doi-mat-khau' ? 'active': ''">
            <div @click.prevent="goToPage('/doi-mat-khau')" class="d-flex align-items-baseline">
              <img src="~/mlearn/icons/menu/profile/change-pass.svg" alt="Đổi mật khẩu" class="mr-2"/>
              Đổi mật khẩu
            </div>
          </div>
          <div class="menu-item">
            <div @click.prevent="logout()" class="d-flex align-items-baseline">
              <img src="~/mlearn/icons/menu/profile/logout.svg" alt="Đăng xuất" class="mr-2"/>
              Đăng xuất
            </div>
          </div>
        </template>
        <template v-else>
          <div class="menu-item">
            <div v-b-modal.modal-login>
              Đăng nhập
            </div>
          </div>
          <div class="menu-item">
            <div v-b-modal.modal-register>
              Đăng ký
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="menu-backdrop" v-if="showMenu" @click="closeMenu"></div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import {mapState} from 'vuex'
import Resource from '~/common/api/resource'
import {removeToken, removeVerify} from '~/common/utils/auth'
import NoAvatar from '~/assets/mlearn/icons/no-avatar.svg'
import Profile from '~/components/mlearn/layout/menu/profile.vue'
import Notify from '~/components/mlearn/layout/menu/notify.vue'
import ClassList from '~/components/mlearn/select/class.vue'
import {EventBus} from '~/common/utils/eventBus'

const profileResource = new Resource('profile/user')
// const classResource = new Resource('category/class')
export default {
  components: {
    Profile,
    Notify,
    ClassList
  },
  data () {
    return {
      NoAvatar,
      showMenu: false,
      showApp: true,
      interestedClass: ''
    }
  },
  head () {
    return {
      bodyAttrs: {
        class: 'ml-scrollbar'
      }
    }
  },
  computed: {
    ...mapState([
      'user'
    ]),
    homeActive () {
      const arr = ['index', 'cau-hoi-slug', 'tra-loi-slug', 'bang-xep-hang', 'hoi-bai-cua-toi', 'noi-quy-hoi-bai', 'huong-dan-cong-diem', 'thong-tin-ca-nhan', 'doi-mat-khau', 'lich-su-thanh-toan', 'dang-ky-gia-su', 'thong-tin-khac', 'search']
      return arr.includes(this.$route.name)
    },
    videoActive () {
      return /khoa-hoc/.test(this.$route.path)
    },
    documentActive () {
      return /tai-lieu/.test(this.$route.path)
    },
    quizActive () {
      return /trac-nghiem/.test(this.$route.path)
    },
    blogActive () {
      return /blog/.test(this.$route.path)
    },
    packActive () {
      return /goi-cuoc/.test(this.$route.path)
    },
    tuhocActive () {
      return /tu-hoc/.test(this.$route.path)
    },
    routeName () {
      return this.$route.name
    }
  },
  mounted () {
    const classId = Cookies.get('interestedClass') || this.user?.class_id
    this.interestedClass = classId || ''
  },
  methods: {
    goToPage (path = '/') {
      this.closeMenu()
      this.$router.push({path})
    },
    openMenu () {
      this.showMenu = true
      document.querySelector('body').classList.add('open-sidebar')
    },
    closeMenu () {
      if (this.showMenu) {
        this.showMenu = false
        document.querySelector('body').classList.remove('open-sidebar')
      }
    },
    findClass (classId) {
      Cookies.set('interestedClass', classId)
      location.reload()
    },
    updateClassInfo (id, item) {
      const {
        id: classId,
        name: classLevel
      } = item
      const data = {
        classLevel,
        classId
      }
      Cookies.set('interestedClass', classId)
      if (this.user != null) {
        profileResource.store(data)
          .then(() => {
            this.$notify({
              type: 'success',
              message: 'Cập nhật lớp học thành công'
            })
            location.reload()
          })
      }
      EventBus.$emit('handleChangeClassMenu', item)
    },
    logout () {
      removeToken()
      removeVerify()
      window.location.href = '/'
    }
  }
}
</script>

<style lang="scss">
.mbl-menu {
  background: var(--white);
  position: fixed;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  top: 0;
  left: 0;
  right: 0;
  z-index: 2000;
  @media (max-width: 576px) {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  }

  .dropdown-verify-class {
    min-width: 88px;
  }

  .mbl-menu-desktop {
    padding: 15px 0 17px;
    @media (max-width: 1366px) {
      padding: 12px 0 12px;
    }
    @media (max-width: 992px) {
    }

    .logo {
      max-height: 42px;
      @media (max-width: 1366px) {
        max-height: 36px;
      }
      @media (max-width: 992px) {
      }
      @media (max-width: 576px) {
        max-height: 28px;
      }
    }

    .list-menu {
      .menu-item {
        font-weight: 500;
        font-size: 18px;
        margin-right: 30px;
        @media (max-width: 1800px) {
          font-size: 16px;
          margin-right: 23px;
        }
        @media (max-width: 1366px) {
          font-size: 14px;
          margin-right: 20px;
        }
        @media (max-width: 992px) {
          margin-right: 20px;
        }

        &:last-child {
          margin-right: 0;
        }

        &.nuxt-link-exact-active {
          color: var(--primary);
          font-weight: 700;
        }
      }
    }

    .register {
      font-weight: 500;
      font-size: 16px;
      @media (max-width: 1800px) {
        font-size: 15px;
      }
      @media (max-width: 1366px) {
        font-size: 15px;
      }
      @media (max-width: 992px) {
      }

      img {
        margin-right: 1rem;
        @media (max-width: 1366px) {
          margin-right: .7rem;
        }
        @media (max-width: 992px) {
        }
      }
    }

    .split {
      color: #CED4DA;
      @media (max-width: 1366px) {
      }
      @media (max-width: 992px) {
      }
    }

    .login {
      font-weight: 500;
      font-size: 16px;
      @media (max-width: 1800px) {
        font-size: 15px;
      }
      @media (max-width: 1366px) {
        font-size: 15px;
      }
      @media (max-width: 992px) {
      }
    }
  }

  .mbl-menu-mobile {
    background: var(--white);
    position: fixed;
    width: 0px;
    top: 0;
    bottom: 0;
    z-index: 9999;
    transition: all .4s ease;
    padding: 0;

    &.to-left {
      right: 0;

      &.open {
        width: 240px;
      }
    }

    &.open {
      .list-menu {
        display: block;
      }
    }

    .close-menu {
      right: 16px;
      top: 15px;
      width: 14px;
    }

    .list-menu {
      display: none;
      padding-top: 40px;
      padding-bottom: 70px;

      .menu-item {
        padding-top: 10px;
        padding-bottom: 10px;
        font-weight: 500;
        font-size: 16px;

        &.menu {
          padding-left: 16px;
          padding-right: 16px;

          &.active {
            color: var(--white);
            background: var(--primary);
            border-radius: 8px;
          }
        }

        &.line {
          padding-top: 0;
          padding-bottom: 0;
          margin: 8px 0;
          border: 1px solid #EBEBEB;
        }

        &.active {
          color: var(--primary);
        }

        &:hover {
          background: var(--primary);
          color: var(--white);
          border-radius: 8px;
        }

        .avatar {
          width: 46px;
          height: 46px;
        }

        .content {
          margin-left: 5px;

          .user-name {
            font-weight: 500;
            font-size: 16px;
            max-width: 150px;
          }

          .user-info {
            font-size: 14px;
            color: #868789;
          }
        }
      }
    }
  }

  .menu-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 4000;
    background-color: #000;
    opacity: .5;
  }
}
</style>
